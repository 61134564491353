import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import store from '../store/store'


// all route for main page
const pagesRoutes = [
    {
        path: '',
        name: 'Home',
        component: () => import ('@/pages/HomePage.vue'),
    },
    {
        path: '/ads',
        component: () => import ('@/pages/ProductList.vue')
    },
    {
        path: '/ads/:location',
        component: () => import ('@/pages/ProductList.vue')
    },
    {
        path: '/ads/:location/:category',
        component: () => import ('@/pages/ProductList.vue')
    },
    {
        path: '/product/:slug',
        component: () => import ('@/pages/ProductDetails.vue')
    },
    {
        path: '/shops/:slug',
        component: () => import ('@/pages/MemberProducts.vue')
    },
    {
        path: '/post-ad',
        component: () => import ('@/pages/PublishAdd.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/otp/:id',
        component: () => import ('@/pages/OtpVerify.vue')
    },
    {
        path: '/forget-password',
        component: () => import ('@/pages/ForgetPassword.vue')
    },
    {
        path: '/change-password/:id',
        component: () => import ('@/pages/ChangePassword.vue')
    },
    {
        path: '/faq',
        component: () => import ('@/pages/FaqPage.vue')
    },
    {
        path: '/help',
        component: () => import ('@/pages/HelpPage.vue')
    },
    {
        path: '/about',
        component: () => import ('@/pages/AboutPage.vue')
    },
    {
        path: '/chat',
        component: () => import ('@/pages/ChatPage.vue')
    },
    {
        path: '/safety-tips',
        component: () => import ('@/pages/SafetyPage.vue')
    },
    {
        path: '/contact-us',
        component: () => import ('@/pages/ContactUs.vue')
    }
];

// all route for profile
const profileRoutes = [
    {
        path: 'dashboard',
        component: () => import ('@/pages/profile/Dashboard.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'ads-list',
        component: () => import ('@/pages/profile/AdsList.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'membership',
        component: () => import ('@/pages/profile/Membership.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'setting',
        component: () => import ('@/pages/profile/Setting.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: 'mobile-numbers',
        component: () => import ('@/pages/profile/MobileNumbers.vue'),
        meta: {
            requiresAuth: true
        }
    }
];

const routes = [
    {
        path: '/',
        name: 'MainLayout',
        component: () => import('@/layouts/MainLayout.vue'),
        children: pagesRoutes
    },
    {
        path: '/profile',
        name: 'ProfileLayout',
        component: () => import('@/layouts/ProfileLayout.vue'),
        children: profileRoutes
    },
    {
        path: '/reunion',
        name: 'ReunionPage',
        component: () => import ('@/pages/ReunionPage.vue'),
    },
]




const router = new VueRouter({
    mode: 'history',
    base: '/',
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    routes
  })

  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next()
            return
        }
        next('/')
    } else {
        next()
    }
})


export default router  