const state = {
	commonObj: {
		hasDropdownLoaded: true,
		categoryList: [],
		subCategoryList: [],
		brandList: [],
		modelList: [],
		featureList: [],
		countryList: [],
		divisionList: [],
		cityList: [],
		areaList: [],
		bannerDate: {},
		settingData: {},
		bannerData: {}
	},
	token: localStorage.getItem('access_token') || null,
	user: JSON.parse(localStorage.getItem('access_user')) || { name: null, picture: null },
	sidebarMinimize: false,
	lang: 'en', 
	static: {
		loading: false,
		listReload: false,
        perPage: 10
	},
	list: []
}

export default state