import Vue from 'vue'
import './plugins'
import './assets/css/main.css'
import router from './router'
import App from './App.vue'
import store from './store/store'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules'
import 'izitoast/dist/css/iziToast.min.css'; // loading css
import VLazyImage from "v-lazy-image";

import moment from 'moment'
Vue.prototype.moment = moment

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ff7674'
}
Vue.use(VueSweetalert2, options)

// Register it globally
Vue.component('VLazyImage', VLazyImage);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
